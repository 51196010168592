import { makeAutoObservable } from 'mobx'
import { resetPassword } from '../services/resetPassword'
import investmentChallengeStore from './investmentChallengeStore'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class ResetPasswordStore {
	isLoading = false
	newPassword = ''
	confirmPassword = ''
	activation_key = ''
	login_key = ''

	isModalSuccessOpen = false
	message = ''

	constructor() {
		makeAutoObservable(this)
	}

	sendResetPassword = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			await resetPassword(this.newPassword, this.confirmPassword, this.login_key, this.activation_key)
			this.resetState()
			investmentChallengeStore.logout()
			this.showModalSuccess('Password reset successfully')
		} catch (error) {
			console.log(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'reset password' }))
			toast.error(error.message)
		} finally {
			this.isLoading = false
		}
	}

	showModalSuccess = (message) => {
		this.isModalSuccessOpen = true
		this.message = message
		setTimeout(() => {
			window.location.href = '/'
			this.isModalSuccessOpen = false
			this.message = ''
		}, 5000)
	}

	resetState = () => {
		this.newPassword = ''
		this.confirmPassword = ''
		this.activation_key = ''
		this.login_key = ''
	}
}

const resetPasswordStore = new ResetPasswordStore()
export default resetPasswordStore
