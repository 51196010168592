import React, { useEffect, useRef, useState } from 'react'

function VideoPlayer({ videSrc, posterSrc, title }) {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleVideoPlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', () => {
        setIsPlaying(false)
      })
      videoRef.current.addEventListener('pause', () => {
        setIsPlaying(false)
      })
      videoRef.current.addEventListener('play', () => {
        setIsPlaying(true)
      })
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', () => {})
        videoRef.current.removeEventListener('pause', () => {})
        videoRef.current.removeEventListener('play', () => {})
      }
    }
  }, [])

  return (
    <>
      <div className='relative h-full'>
        <video className='object-cover object-center w-full h-full rounded-lg' preload='none' poster={posterSrc} ref={videoRef} controls={true}>
          <source src={videSrc} type='video/mp4' />
        </video>
        {!isPlaying && (
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <button onClick={handleVideoPlayPause}>
              <i className='fa fa-play text-white' style={{ fontSize: '50px' }}></i>
            </button>
          </div>
        )}
        {!isPlaying && <h3 className='absolute top-5 left-5 font-bold text-sm md:text-xl text-white pointer-events-none drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'>{title}</h3>}
      </div>
    </>
  )
}

export default VideoPlayer
