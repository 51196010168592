import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { successStoryStore } from '../../../stores/_rootStore'
import { useNavigate } from 'react-router-dom'
import HeadingUnderline from '../../../components/HeadingUnderline'
import Card from '../../../components/Card'

function SuccessStory() {
	const { t } = useTranslation()
	const [searchTerm, setSearchTerm] = useState('')
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			try {
				await successStoryStore.fetchData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		return () => {
			successStoryStore.resetState()
		}
	}, [])

	return (
		<>
			<h1 className='text-3xl font-bold mb-2 mt-8'>{t('success-story')}</h1>
			<HeadingUnderline />
			<div className='flex flex-col mt-7'>
				<div className='flex justify-end'>
					<div className='relative'>
						<input
							type='text'
							className='border border-gray-300 py-2 pl-2 pr-7 focus:outline-none focus:border-gold'
							placeholder={t('search')}
							onChange={(e) => setSearchTerm(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									successStoryStore.search(searchTerm)
								}
							}}
						/>
						<i className='fa fa-search absolute right-2 top-3 text-gray-300'></i>
					</div>
				</div>
				<div className='grid grid-cols-2 md:grid-cols-3 gap-4 py-5'>
					{successStoryStore.data.map((post) => (
						<Card
							key={post.id}
							imgSrc={`${process.env.REACT_APP_IMAGE_URL}${post.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
							label={post.post_title}
							href={`/investment/success-story/${post.post_name}`}
						/>
					))}
					{successStoryStore.isLoading ? <div>Loading...</div> : successStoryStore.data.length === 0 && <p>{t('no-data')}</p>}
				</div>
				{successStoryStore.hasNext && (
					<button className='bg-gold text-white py-2 px-4 rounded-md' onClick={successStoryStore.nextPage}>
						{t('view-more')}
					</button>
				)}
			</div>
		</>
	)
}

export default observer(SuccessStory)
