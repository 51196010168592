import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/swiper-bundle.css'
import VideoPlayer from './VideoPlayer'

export default function AtAGlance() {
  const { t } = useTranslation()
  const swiperRef = useRef(null)
  const buttonNextRef = useRef(null)
  const buttonPrevRef = useRef(null)
  const handleVideoPlay = () => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.stop()
    }
  }

  return (
    <div className='mb-16'>
      <div className='mx-8 lg:mx-16 mt-16'>
        <Swiper
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true
          }}
          style={{ width: '100%' }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
            const videos = document.querySelectorAll('video')
            videos.forEach((video) => {
              video.addEventListener('play', handleVideoPlay)
            })
            swiper.on('slideChange', () => {
              const videos = document.querySelectorAll('video')
              videos.forEach((video) => {
                video.pause()
              })
            })
          }}
        >
          <SwiperSlide>
            <VideoPlayer posterSrc={'/assets/images/thumbnail-cjibf.png'} videSrc={'/assets/videos/cjibf.mp4'} title={'Road to CJIBF'} />
          </SwiperSlide>

          <SwiperSlide>
            <VideoPlayer posterSrc={'/assets/images/thumbnail-seremoni-cjibf.png'} videSrc={'/assets/videos/seremoni_cjibf.mp4'} title={t('ceremony_cjibf')} />
          </SwiperSlide>

          <div className='swiper-button-prev text-white' ref={buttonPrevRef}></div>
          <div className='swiper-button-next text-white' ref={buttonNextRef}></div>
        </Swiper>
      </div>
    </div>
  )
}
