import React, { useState } from "react";
import AboutSection from "../../components/AboutUs/AboutSection";
import VisiMisi from "../../components/AboutUs/VisiMisi";
import KegiatanKami from "../../components/AboutUs/KegiatanKami";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from '../../components/Sidebar'

const AboutUs = () => {
  const { t } = useTranslation()

  const breadcrumbItems = [
    {
      label: (
        <>
          <i className='ms-2 fa fa-home'></i> {t('home')}
        </>
      ),
      href: '/'
    },
    { label: t('about-us') }
  ]

  const tabs = [
    {
      key: 'about',
      content: <AboutSection />
    },
    {
      key: 'visiMisi',
      content: <VisiMisi />
    },
    {
      key: 'kegiatanKami',
      content: <KegiatanKami />
    }
  ]

  return (
    <div className='relative'>
      <div className='flex justify-center relative z-10'>
        <div className='max-w-6xl w-full mt-[30px] px-4'>
          <Breadcrumb items={breadcrumbItems} />
          <div className='md:flex mt-7'>
            <Sidebar tabData={tabs} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
