import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { newsStore } from '../../stores/_rootStore'
import { formatDate } from '../../lib/utils'
import HeadingUnderline from '../../components/HeadingUnderline'
import Breadcrumb from '../../components/Breadcrumb'
import Card from '../../components/Card'
import i18n from '../../i18n'

function News() {
	const { t } = useTranslation()
	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('newsevent') }]
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			try {
				await newsStore.fetchData()
			} catch (error) {
				console.error(error)
			}
		}

		fetchData()
		// eslint-disable-next-line
	}, [i18n.language])

	return (
		<div className='flex flex-col justify-center mb-12'>
			<div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
				<Breadcrumb items={breadcrumbItems} />
				<h1 className='text-3xl font-bold mb-2 mt-8'>{t('newsevent')}</h1>
				<HeadingUnderline />
				{newsStore.isLoading && <p>Loading...</p>}
				{newsStore.data.length > 0 && (
					<>
						<div className='grid grid-cols-1 md:grid-cols-3 gap-2 gap-y-5 mt-5 bg-white'>
							{newsStore.data.map((item) => (
								<Card
									key={item.post_title}
									label={item.post_title}
									href={`/news/${item.post_name}`}
									imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item?.post_meta.filter((meta) => meta.meta_key === 'banner')[0]?.meta_value}`}
									footer={<p className='text-right text-[11px]'>{formatDate(item.post_date)}</p>}
								/>
							))}
						</div>
						{newsStore.hasNext && (
							<div className='flex justify-center mt-5'>
								<button className='bg-primary text-white py-2 px-4 rounded-md' onClick={newsStore.nextPage}>
									{t('view-more')}
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default observer(News)
