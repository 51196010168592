import baseService from './baseService'
import i18n from '../i18n'

export const fetchAnnouncement = async (page, limit) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', type: 'challenge-pengumuman', meta: true }
		})
		const data = response.data
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchAboutIC = async (page, limit) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', type: 'challenge-berita', meta: true }
		})
		const data = response.data
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchTimeline = async (page, limit) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', type: 'challenge-timeline', meta: true }
		})
		const data = response.data
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const login = async (login_key, password, login_method) => {
	try {
		const response = await baseService.post('login', { login_key, password, login_method }, { headers: { 'Content-Type': 'application/json' }, params: { lang: i18n.language } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const register = async (name, login_key, password, confirm_password, birthday, birth_place) => {
	try {
		const response = await baseService.post(
			'register',
			{ name, login_key, password, confirm_password, birthday, birth_place },
			{ headers: { 'Content-Type': 'application/json' }, params: { lang: i18n.language } }
		)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const forgotPassword = async (login_key) => {
	try {
		const response = await baseService.post('request-reset-password', { login_key }, { headers: { 'Content-Type': 'application/json' }, params: { lang: i18n.language } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const sendInvestmentChallenge = async (data) => {
	try {
		const response = await baseService.post('investmentChallenge', data, { headers: { 'Content-Type': 'multipart/form-data' }, params: { lang: i18n.language } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const uploadPhotoProfile = async (data) => {
	try {
		const response = await baseService.post('investmentChallenge/uploadPhotoProfile', data, { headers: { 'Content-Type': 'multipart/form-data' }, params: { lang: i18n.language } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchHistoryUpload = async (user_id) => {
	try {
		const response = await baseService.get('investmentChallenge', {
			params: { lang: i18n.language, user_id: user_id }
		})
		const data = response.data
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteHistoryUpload = async (id) => {
  try {
    const response = await baseService.delete(`investmentChallenge/${id}`, {
      params: { lang: i18n.language }
    })
    const data = response.data
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
