import baseService from "./baseService";
import i18n from "../i18n";

export const fetchAllTourism = async (page, limit, search, categories, region) => {
	try {
		const response = await baseService.get('post', {
			params: {
				lang: i18n.language,
				page: page,
				limit: limit,
				status: 'publish',
				type: 'pariwisata-detail',
				meta: true,
				search: search,
				'term-slug': categories,
				'post-name-relate': region
			}
		})
		const data = response.data
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const downloadCalendarEvents = async (payload) => {
	try {
		console.log({ payload })
		const response = await baseService.post('download/calendar-events', payload, { params: { lang: i18n.language } })
		const data = response.data
		console.log('service', { data })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}