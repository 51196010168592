import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const KegiatanKami = () => {
	const { t } = useTranslation()

	const [isFullscreen, setIsFullscreen] = useState(false)
	useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      setIsFullscreen(document.fullscreenElement !== null)
    })
    // eslint-disable-next-line
  }, [document.fullscreenElement])

  return (
    <div className='kegiatan-kami space-y-8 pb-16' id='kegiatan-kami'>
      <h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2'>{t('our-activities')}</h3>

      {[...Array(15)].map((_, i) => (
        <div className='kegiatan-kami-container text-center border-b-4 border-gray-300 pb-6' key={i}>
          <h4 className='font-semibold text-lg'>{t(`activities-title-${i + 1}`)}</h4>
          <div
            className={`${isFullscreen && 'bg-slate-900'} relative w-3/4 max-w-md mx-auto my-4 flex items-center justify-center cursor-pointer`}
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          >
            <button className={`${isFullscreen ? '' : 'hidden'} absolute top-5 right-5 text-4xl text-white`} onClick={() => document.exitFullscreen()}>
              <i className='fa fa-close'></i>
            </button>
            <img src={`/assets/images/about_us/kegiatan-${i + 1}.png`} alt={`Kegiatan Kami ${i + 1}`} />
          </div>
          <p className='text-sm pt-3'>{t(`activities-description-${i + 1}`)}</p>
        </div>
      ))}
    </div>
  )
}

export default KegiatanKami
