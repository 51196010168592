import { makeAutoObservable } from 'mobx'
import { fetchDetailPostByPostName, fetchIndustrialArea } from '../services/investmentService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class IndustrialAreaDetailStore {
	isLoading = false
	data = undefined
	otherData = []

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async (slug) => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchDetailPostByPostName(slug)
			this.data = response.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'industrial area detail' }))
		} finally {
			this.isLoading = false
		}
	}

	fetchOtherData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchIndustrialArea(1, 5)
			this.otherData = response.data.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'other industrial area' }))
		} finally {
			this.isLoading = false
		}
	}
}

const industrialAreaDetailStore = new IndustrialAreaDetailStore()
export default industrialAreaDetailStore
