import React, { useEffect } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useTranslation } from 'react-i18next'
import { uploadFileInvestmentChallengeStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import InputFile from './InputFile'
import { Cross1Icon } from '@radix-ui/react-icons'

function UploadFile() {
	const { t } = useTranslation()
	return (
		<Dialog.Root open={uploadFileInvestmentChallengeStore.isModalUploadFileOpen} onOpenChange={(isOpen) => (uploadFileInvestmentChallengeStore.isModalUploadFileOpen = isOpen)}>
			<button className='bg-gold p-2 text-white hover:opacity-80 rounded-md' onClick={() => (uploadFileInvestmentChallengeStore.isModalUploadFileOpen = true)}>
				{t('upload-file')}
			</button>
			<Dialog.Portal>
				<Dialog.Overlay className='DialogOverlay' />
				<Dialog.Content className='DialogContent' style={{ maxWidth: '100%', width: '90vw', overflow: 'auto' }} onPointerDownOutside={(e) => e.preventDefault()}>
					<VisuallyHidden.Root>
						<Dialog.Title>{t('upload-file')}</Dialog.Title>
					</VisuallyHidden.Root>
					<div>
						<h2 className='text-2xl font-bold text-center md:mb-10'>{t('upload-file')}</h2>
						<div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
							<div className='order-1 md:order-none'>
								<input
									type='text'
									placeholder={t('uploader-name')}
									className='border border-slate-300 p-2 w-full'
									value={uploadFileInvestmentChallengeStore.name}
									onChange={(e) => {
										uploadFileInvestmentChallengeStore.name = e.target.value
									}}
								/>
							</div>
							<div className='order-6 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_proposal?.name || t('proposal-file')}
									description={t('proposal-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_proposal = file
									}}
									maxSize={52428800}
									supportedFormat={['docx']}
								/>
							</div>
							<div className='order-2 md:order-none'>
								<input
									type='text'
									placeholder={t('uploader-service-origin')}
									className='border border-slate-300 p-2 w-full'
									value={uploadFileInvestmentChallengeStore.dinas}
									onChange={(e) => {
										uploadFileInvestmentChallengeStore.dinas = e.target.value
									}}
								/>
							</div>
							<div className='order-7 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_presentation?.name || t('presentation-file')}
									description={t('presentation-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_presentation = file
									}}
									maxSize={52428800}
									supportedFormat={['pptx']}
								/>
							</div>
							<div className='order-3 md:order-none'>
								<input
									type='text'
									placeholder={t('uploader-region-origin')}
									className='border border-slate-300 p-2 w-full'
									value={uploadFileInvestmentChallengeStore.region}
									onChange={(e) => {
										uploadFileInvestmentChallengeStore.region = e.target.value
									}}
								/>
							</div>
							<div className='order-8 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_support_1?.name || t('support-file')}
									description={t('support-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_support_1 = file
									}}
									maxSize={10485760}
									supportedFormat={['jpg', 'jpeg', 'png', 'docx', 'xlsx']}
								/>
							</div>
							<div className='order-4 md:order-none'>
								<input
									type='text'
									placeholder={t('title-investment-profile')}
									className='border border-slate-300 p-2 w-full'
									value={uploadFileInvestmentChallengeStore.title}
									onChange={(e) => {
										uploadFileInvestmentChallengeStore.title = e.target.value
									}}
								/>
							</div>
							<div className='order-9 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_support_2?.name || t('support-file')}
									description={t('support-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_support_2 = file
									}}
									maxSize={10485760}
									supportedFormat={['jpg', 'jpeg', 'png', 'docx', 'xlsx']}
								/>
							</div>
							<div className='order-5 md:order-none'>
								<input
									type='text'
									placeholder={t('sector-investment-project')}
									className='border border-slate-300 p-2 w-full'
									value={uploadFileInvestmentChallengeStore.sector}
									onChange={(e) => {
										uploadFileInvestmentChallengeStore.sector = e.target.value
									}}
								/>
							</div>
							<div className='order-10 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_support_3?.name || t('support-file')}
									description={t('support-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_support_3 = file
									}}
									maxSize={10485760}
									supportedFormat={['jpg', 'jpeg', 'png', 'docx', 'xlsx']}
								/>
							</div>
							<div></div>
							<div className='order-11 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_support_4?.name || t('support-file')}
									description={t('support-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_support_4 = file
									}}
									maxSize={10485760}
									supportedFormat={['jpg', 'jpeg', 'png', 'docx', 'xlsx']}
								/>
							</div>
							<div></div>
							<div className='order-12 md:order-none'>
								<InputFile
									placeholder={uploadFileInvestmentChallengeStore.file_support_5?.name || t('support-file')}
									description={t('support-file-desc')}
									setFile={(file) => {
										uploadFileInvestmentChallengeStore.file_support_5 = file
									}}
									maxSize={10485760}
									supportedFormat={['jpg', 'jpeg', 'png', 'docx', 'xlsx']}
								/>
							</div>
						</div>
						<div className='w-full mt-10'>
							<button className='w-full bg-gold text-white py-3' onClick={uploadFileInvestmentChallengeStore.submitInvestmentChallenge}>
								{t('save')}
							</button>
						</div>
					</div>
					<Dialog.Close asChild onClick={uploadFileInvestmentChallengeStore.resetState}>
						<button className='IconButton' aria-label='Close'>
							<Cross1Icon />
						</button>
					</Dialog.Close>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

export default observer(UploadFile)
