import React from 'react'
import HeadingUnderline from '../HeadingUnderline'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

function DownloadMaterial() {
	const { t } = useTranslation()

	return (
		<div>
			<div className='mb-2'>
				<h1 className='text-3xl font-bold'>{t('download-material')}</h1>
				<HeadingUnderline />
				<div className='mt-2 flex flex-col gap-3'>
					<a href='https://drive.google.com/drive/folders/179QxGJsq10m1swayw4BbZWDZYEeEPfyw' className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
						HLM Keris Jateng 2024
					</a>
					<a href='https://drive.google.com/drive/folders/1lx_zdsAs_956jHxaTo_CSq3dsWr4rCas' className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
						Capacity Building Keris Jateng 2024
					</a>
					<a href='https://drive.google.com/drive/folders/1GNGy8FagR19I3FJIIEOsUuTrZCBgqeDc' className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
						CJIBF 2024
					</a>
				</div>
			</div>
		</div>
	)
}
export default observer(DownloadMaterial)
