import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function InputFile({ placeholder, description, setFile, maxSize = 52428800, supportedFormat = ['pdf'] }) {
	const [isError, setIsError] = useState(false)
	const { t } = useTranslation()
	const fileInputRef = useRef(null)
	const handleFileChange = (event) => {
		const file = event.target.files[0]
		if (file) {
			if (file.size > maxSize) {
				setIsError(true)
				return
			}
			const fileExtension = file.name.split('.').pop().toLowerCase()
			if (!supportedFormat.includes(fileExtension)) {
				setIsError(true)
				return
			}
			console.log('Selected file:', file)
			setFile(file)
		}
	}
	const handleUploadClick = () => {
		setIsError(false)
		fileInputRef.current.click()
	}
	return (
		<>
			<div className={`border ${isError ? 'border-red-500' : 'border-slate-300'} p-2 flex justify-between`}>
				<p className='text-slate-400 text-ellipsis'>{placeholder}</p>
				<button className='border border-gold text-gold font-bold px-2 rounded-md hover:bg-gold hover:text-white' onClick={handleUploadClick}>
					{t('upload')}
				</button>
				<input type='file' className='hidden' ref={fileInputRef} onChange={handleFileChange} />
			</div>
			<p className={`${isError ? 'text-red-500' : 'text-slate-400'} text-xs`}>{description}</p>
		</>
	)
}

export default observer(InputFile)
