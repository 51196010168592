import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { investmentChallengeStore } from '../../stores/_rootStore'
import HomeSlider from '../../components/Home/HomeSlider'
import Breadcrumb from '../../components/Breadcrumb'
import Profile from '../../components/Challenge/Profile'
import Announcement from '../../components/Challenge/Announcement'
import Timeline from '../../components/Challenge/Timeline'
import Tor from '../../components/Challenge/Tor'
import DownloadMaterial from '../../components/Challenge/DownloadMaterial'
import Documentation from '../../components/Challenge/Documentation'
import UploadFile from '../../components/Challenge/UploadFile'
import ModalLogin from './ModalLogin'
import AboutIC from '../../components/Challenge/AboutIC'

function InvestmentChallenge() {
	const photoPath = process.env.REACT_APP_IMAGE_URL
	const { t } = useTranslation()
	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('investment-challenge') }]
	const tabs = [
		{
			key: 'profile',
			content: <Profile />
		},
		{
			key: 'announcement',
			content: <Announcement />
		},
		{
			key: 'timeline',
			content: <Timeline />
		},
		{
			key: 'tor',
			content: <Tor />
		}
	]

	const notLoginTabs = [
		{
			key: 'login',
			onClick: () => {
				investmentChallengeStore.isModalLoginOpen = true
			}
		},
		{
			key: 'about',
			content: <AboutIC />
		}
	]

	const activityTabs = [
		{
			key: 'download-material',
			content: <DownloadMaterial />
		},
		{
			key: 'documentation',
			content: <Documentation />
		}
	]

	const handleTabChange = (tabKey) => {
		investmentChallengeStore.activeTab = tabKey
	}

	const TabButton = ({ label, isActive, onClick }) => {
		return (
			<button
				className={`w-full text-center md:text-left my-1 px-6 box-border py-3 uppercase rounded-md md:rounded-none border border-gray-300 md:border-0 ${
					isActive && 'font-bold border-b-4 md:border-l-4 border-b-gold md:border-b-0 md:border-l-gold bg-[#e0aa3e33]'
				}`}
				onClick={onClick}
			>
				{label}
			</button>
		)
	}

	const TabContent = ({ activeTab, tabs, contentWidth }) => {
		const style = {
			'@media (maxWidth: 768px)': {
				width: `${contentWidth}%`
			},
			width: `100%`
		}
		return (
			<div style={style} className='pt-4 md:pt-0 md:p-4'>
				{tabs.map((tab) => (
					<div key={tab.key}>{activeTab === tab.key && tab.content}</div>
				))}
			</div>
		)
	}

	useEffect(() => {
		if (localStorage.getItem('token')) {
			investmentChallengeStore.isLoggedIn = true
			investmentChallengeStore.isModalLoginOpen = false
			investmentChallengeStore.activeTab = 'profile'
		} else {
			investmentChallengeStore.isLoggedIn = false
			investmentChallengeStore.isModalLoginOpen = true
			investmentChallengeStore.activeTab = 'about'
		}
		// eslint-disable-next-line
	}, [localStorage.getItem('token')])

	useEffect(() => {
		if (localStorage.getItem('login_expired')) {
			const login_expired_string = JSON.parse(localStorage.getItem('login_expired'))
			const login_expired = new Date(login_expired_string).getTime() / 1000
			const now = new Date().getTime() / 1000
			if (now > login_expired) {
				investmentChallengeStore.logout()
			}
		}
	}, [])

	return (
		<div>
			<ModalLogin />
			<HomeSlider isHome={false} />
			<div className='max-w-6xl w-full mt-5 mb-12 md:mt-5 p-2 md:p-0 md:mx-auto'>
				<div className='flex justify-between'>
					<Breadcrumb items={breadcrumbItems} />
					{investmentChallengeStore.activeTab !== 'profile' && investmentChallengeStore.isLoggedIn && (
						<div className='text-3xl flex justify-between gap-2 items-center'>
							<span className='text-xl'>{JSON.parse(localStorage.getItem('user')).name}</span>
							{JSON.parse(localStorage.getItem('user')).photo_profile ? (
								<img
									src={`${photoPath}${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).photo_profile : ''}`}
									alt='Profile'
									onError={(e) => (e.target.src = 'assets/images/no-data-image.jpg')}
									width={30}
									className='rounded-full border border-slate-300'
								/>
							) : (
								<i className='fa fa-user-circle'></i>
							)}
						</div>
					)}
				</div>
				<div className='flex mt-7 flex-col md:flex-row'>
					<div className='flex flex-col gap-2 md:w-1/3'>
						<div className='w-full py-2 md:bg-gray-200 md:max-h-screen text-base md:text-lg flex md:block gap-1 overflow-auto'>
							{investmentChallengeStore.isLoggedIn
								? tabs.map((tab) => <TabButton key={tab.key} label={t(tab.key)} isActive={investmentChallengeStore.activeTab === tab.key} onClick={() => handleTabChange(tab.key)} />)
								: notLoginTabs.map((tab) =>
										tab.onClick ? (
											<TabButton key={tab.key} label={t(tab.key)} onClick={tab.onClick} />
										) : (
											<TabButton key={tab.key} label={t(tab.key)} isActive={investmentChallengeStore.activeTab === tab.key} onClick={() => handleTabChange(tab.key)} />
										)
								  )}
						</div>
						{investmentChallengeStore.isLoggedIn && (
							<div className='w-full py-2 md:bg-gray-200 md:max-h-screen text-base md:text-lg flex flex-col md:block gap-1'>
								<div className='w-full text-center md:text-left px-6 py-3 uppercase font-bold'>{t('kerisjateng-activity')}</div>
								<div className='flex gap-2 md:block'>
									{activityTabs.map((tab) => (
										<TabButton key={tab.key} label={t(tab.key)} isActive={investmentChallengeStore.activeTab === tab.key} onClick={() => handleTabChange(tab.key)} />
									))}
								</div>
							</div>
						)}
						{investmentChallengeStore.activeTab !== 'profile' && investmentChallengeStore.isLoggedIn && (
							<div className='flex justify-center items-center'>
								<UploadFile />
							</div>
						)}
					</div>
					<TabContent activeTab={investmentChallengeStore.activeTab} tabs={tabs.concat(activityTabs).concat(notLoginTabs)} />
				</div>
			</div>
		</div>
	)
}

export default observer(InvestmentChallenge)
