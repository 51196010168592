import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import HeadingUnderline from '../HeadingUnderline'
import { investmentChallengeStore } from '../../stores/_rootStore'
import i18n from '../../i18n'
import { observer } from 'mobx-react'

function Announcement() {
	const { t } = useTranslation()
	useEffect(() => {
    const fetchData = async () => {
      await investmentChallengeStore.fetchAnnouncement()
    }
    fetchData()
    //eslint-disable-next-line
  }, [i18n.language])
  return (
    <div>
      <div className='mb-2'>
        <h1 className='text-3xl font-bold'>{t('announcement')}</h1>
        <HeadingUnderline />
        {investmentChallengeStore.isLoading && <div>Loading...</div>}
        {investmentChallengeStore.announcement && (
          <div className='mt-2'>
            <img src={`${process.env.REACT_APP_IMAGE_URL}${investmentChallengeStore.announcement.post_meta.filter((item) => item.meta_key === 'banner')[0].meta_value}`} alt='Banner IC' />
            <div className='innerhtml' dangerouslySetInnerHTML={{ __html: investmentChallengeStore.announcement.post_content }}></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(Announcement)
