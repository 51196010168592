import { makeAutoObservable, toJS } from 'mobx'
import { fetchDetailPostByPostName, fetchInvestmentPotential } from '../services/investmentService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class InvestmentPotentialDetailStore {
	isLoading = false
	data = undefined
	otherData = []

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async (slug) => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchDetailPostByPostName(slug)
			this.data = response.data
			console.log(toJS(this.data))
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'investment potential' }))
		} finally {
			this.isLoading = false
		}
	}

	fetchOtherData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchInvestmentPotential(1, 5)
			this.otherData = response.data.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'investment potential' }))
		} finally {
			this.isLoading = false
		}
	}
}

const investmentPotentialDetailStore = new InvestmentPotentialDetailStore()
export default investmentPotentialDetailStore
