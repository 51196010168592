export const handleImageError = (e) => {
	e.target.src = '../assets/images/no-data-image.jpg'
}

export const formatDate = (dateString) => {
	const date = new Date(dateString)

	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const year = date.getFullYear()

	const formattedDate = `${day}/${month}/${year}`
	return formattedDate
}

export const validateImageResolution = async (file, maxWidth, maxHeight) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = function (event) {
			const img = new Image()
			img.onload = function () {
				const width = img.width
				const height = img.height

				if (width <= maxWidth && height <= maxHeight) {
					resolve(true)
				} else {
					reject(new Error('Invalid resolution'))
				}
			}
			img.src = event.target.result
		}

		reader.onerror = function () {
			reject(new Error('Error reading file'))
		}

		reader.readAsDataURL(file)
	})
}
