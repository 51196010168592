import React from 'react'
import { handleImageError } from '../lib/utils'
import { Link } from 'react-router-dom'

function Card({ imgSrc, label, href, footer }) {
	return (
		<Link className='border border-gray-300 rounded-md bg-white flex flex-col justify-between' to={href}>
			<div>
				<img
					src={imgSrc}
					alt={label}
					className='w-full h-auto aspect-square max-h-48 object-cover rounded-t-md'
					onError={(e) => {
						handleImageError(e)
					}}
				/>
				<h3 className='p-2 font-semibold text-base 2xl:text-xl'>{label}</h3>
			</div>
			<div className='p-2'>{footer}</div>
		</Link>
	)
}

export default Card
