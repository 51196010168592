import axios from 'axios'
import { toast } from 'react-toastify'
const baseUrl = process.env.REACT_APP_API_URL
const baseService = axios.create({})

baseService.interceptors.request.use(
	(config) => {
		config.baseURL = baseUrl
		config.headers.accept = 'application/json'
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

baseService.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response) {
			console.error('Error response:', error.response)
		} else if (error.request) {
			console.error('No response received:', error.request)
		} else {
			console.error('Error setting up request:', error.message)
		}
		return Promise.reject(error)
	}
)

export default baseService
