import React, { useEffect } from 'react'
import PageDetail from '../../../components/PageDetail'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { infrastructureDetailStore } from '../../../stores/_rootStore'
import Card from '../../../components/Card'
import i18n from '../../../i18n'

function InfrastuctureDetail() {
	const { t } = useTranslation()
	const { postName } = useParams()
	const navigate = useNavigate()
	useEffect(() => {
		const fetchData = async () => {
			try {
				await infrastructureDetailStore.fetchData(postName)
				await infrastructureDetailStore.fetchOtherData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		// eslint-disable-next-line
	}, [postName, i18n.language])

	const ContentRight = () => {
		return (
			<>
				<h1 className='font-bold text-2xl pb-2'>{t('other-infrastructure')}</h1>
				<div className='grid grid-cols-2 gap-5'>
					{infrastructureDetailStore.otherData.map((item) => (
						<Card
							imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
							label={item.post_title}
							href={`/investment/infrastructure/${item.post_name}`}
						/>
					))}
				</div>
			</>
		)
	}

	const breadcrumbItems = [
		{ label: t('home'), href: '/' },
		{ label: t('invest') },
		{ label: t('facility-infrastructure') },
		{ label: t('infrastructure') },
		{ label: infrastructureDetailStore.data?.post_title }
	]
	if (infrastructureDetailStore.isLoading) return <div>Loading...</div>
	if (infrastructureDetailStore.data !== undefined)
		return (
			<div>
				<PageDetail breadcrumbItems={breadcrumbItems} bannerImg={infrastructureDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value} contentRight={<ContentRight />}>
					<h1 className='font-bold text-2xl pb-2'>{infrastructureDetailStore.data.post_title}</h1>
					<hr />
					<p dangerouslySetInnerHTML={{ __html: infrastructureDetailStore.data.post_content }}></p>
				</PageDetail>
			</div>
		)
}

export default observer(InfrastuctureDetail)
