import React from 'react'
import HeadingUnderline from '../HeadingUnderline'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

function Tor() {
	const { t } = useTranslation()

	return (
		<div>
			<div className='mb-2'>
				<h1 className='text-3xl font-bold'>{t('tor')}</h1>
				<HeadingUnderline />
				<div className='mt-2 flex flex-col gap-3'>
					<div className='flex justify-between'>
						<p className='text-blue-400 text-2xl font-bold'>TOR 2024</p>
						<a
							href='https://drive.google.com/drive/folders/1ZME-3UHyFc7P44L-mepsMKulM8nKuN5W?usp=sharing'
							target='_blank'
							rel='noreferrer'
							className='bg-gold text-white text-2xl p-2 rounded-md hover:opacity-80'
						>
							{t('download')}
						</a>
					</div>
					<div className='flex justify-between'>
						<p className='text-blue-400 text-2xl font-bold'>Template Dokumen 2024</p>
						<a href='https://drive.google.com/drive/folders/1ZME-3UHyFc7P44L-mepsMKulM8nKuN5W' target='_blank' rel='noreferrer' className='bg-gold text-white text-2xl p-2 rounded-md hover:opacity-80'>
							{t('download')}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
export default observer(Tor)
