import { makeAutoObservable } from 'mobx'
import { fetchDetailPostByPostName } from '../services/investmentService'
import { fetchAllTourism } from '../services/tourismService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class TourismDetailState {
	isLoading = false
	data = undefined
	otherData = []

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async (postName) => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchDetailPostByPostName(postName)
			this.data = response.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'investment potential' }))
		} finally {
			this.isLoading = false
		}
	}

	fetchOtherData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchAllTourism(1, 5)
			this.otherData = response.data.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'investment potential' }))
		} finally {
			this.isLoading = false
		}
	}
}

const TourismDetailStore = new TourismDetailState()
export default TourismDetailStore
