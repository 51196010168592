import baseService from './baseService'
import i18n from '../i18n'

export const resetPassword = async (newPassword, confirmPassword, login_key, activation_key) => {
	try {
		baseService.post(
			'reset-password',
			{ new_password: newPassword, confirm_password: confirmPassword, login_key, activation_key },
			{ headers: { 'Content-Type': 'application/json' }, params: { lang: i18n.language } }
		)
	} catch (error) {
		return Promise.reject(error)
	}
}
