import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { investmentChallengeStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useTranslation } from 'react-i18next'

function ModalConfirmDeleteFile() {
  const { t } = useTranslation()
  return (
    <Dialog.Root open={investmentChallengeStore.isModalConfirmDeleteOpen} onOpenChange={() => (investmentChallengeStore.isModalConfirmDeleteOpen = !investmentChallengeStore.isModalConfirmDeleteOpen)}>
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content
          className='DialogContent'
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <VisuallyHidden>
            <Dialog.Title>Confirm Delete</Dialog.Title>
          </VisuallyHidden>
          <div className='flex justify-end'>
            <button onClick={investmentChallengeStore.closeConfirmDelete}>
              <Cross1Icon />
            </button>
          </div>
          <div className='flex flex-col justify-center gap-3'>
            <p className='text-xl font-bold text-center'>{t('confirm-delete-ic')}</p>
            <div className='flex justify-center gap-3'>
              <button className='bg-red-500 hover:bg-red-400 text-white w-20 h-8 rounded-md' onClick={investmentChallengeStore.deleteHistoryUpload}>
                {t('delete')}
              </button>
              <button className='bg-slate-600 hover:bg-slate-500 text-white w-20 h-8 rounded-md' onClick={investmentChallengeStore.closeConfirmDelete}>
                {t('cancel')}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default observer(ModalConfirmDeleteFile)
