import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const { t } = useTranslation();
  const desc = t('desc-about-us');

  const DescComponent = () => {
    const boldText =  "KERIS JATENG";
    const parts = desc.split(boldText);

    return (
      <div>
        {parts[0]}
        <strong>{boldText}</strong>
        {parts[1]}
        <strong>{boldText}</strong>
        {parts[2]}
      </div>
    );
  }

  return (
    <div className='about-us space-y-6' id='about-us'>
      <h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2'>{t('about-us-section')}</h3>
      <div className='flex flex-col md:flex-row items-start my-4'>
        <div className='flex justify-center w-full md:block md:w-1/4 md:mr-5'>
          <img src='/assets/images/logo-border.png' alt='' className='md:w-full max-w-xs h-auto' />
        </div>
        <div className='md:w-3/4 body-about text-justify font-semibold pr-0 md:pr-4'>
          <DescComponent />
        </div>
      </div>
    </div>
  )
};

export default AboutSection;
