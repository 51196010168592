import IndustrialAreaStore from './industrialAreaStore'
import InfrastructureStore from './infrastructureStore'
import InvestmentPotentialStore from './investmentPotentialStore'
import SuccessStoryStore from './successStoryStore'
import IndustrialAreaDetailStore from './industrialAreaDetailStore'
import InfrastructureDetailStore from './infrastructureDetailStore'
import SuccessStoryDetailStore from './successStoryDetailStore'
import InvestmentPotentialDetailStore from './investmentPotentialDetailStore'
import NewsStore from './newsStore'
import NewsDetailStore from './newsDetailStore'
import InvestmentRealizationStore from './investmentRealizationStore'
import LinkStore from './linkStore'
import RegionStore from './regionStore'
import InvestmentChallengeStore from './investmentChallengeStore'
import UploadFileInvestmentChallengeStore from './uploadfFileInvestmentChallengeStore'
import ResetPasswordStore from './resetPasswordStore'
import TourismStore from './tourismStore'
import TourismDetailStore from './tourismDetailStore'

class RootStore {
	industrialAreaStore = IndustrialAreaStore
	infrastructureStore = InfrastructureStore
	investmentPotentialStore = InvestmentPotentialStore
	successStoryStore = SuccessStoryStore
	industrialAreaDetailStore = IndustrialAreaDetailStore
	infrastructureDetailStore = InfrastructureDetailStore
	successStoryDetailStore = SuccessStoryDetailStore
	investmentPotentialDetailStore = InvestmentPotentialDetailStore
	newsStore = NewsStore
	newsDetailStore = NewsDetailStore
	tourismStore = TourismStore
	tourismDetailStore = TourismDetailStore
	investmentRealizationStore = InvestmentRealizationStore
	linkStore = LinkStore
	regionStore = RegionStore
	investmentChallengeStore = InvestmentChallengeStore
	uploadFileInvestmentChallengeStore = UploadFileInvestmentChallengeStore
	resetPasswordStore = ResetPasswordStore
}

const rootStore = new RootStore()

export const industrialAreaStore = rootStore.industrialAreaStore
export const infrastructureStore = rootStore.infrastructureStore
export const investmentPotentialStore = rootStore.investmentPotentialStore
export const successStoryStore = rootStore.successStoryStore
export const industrialAreaDetailStore = rootStore.industrialAreaDetailStore
export const infrastructureDetailStore = rootStore.infrastructureDetailStore
export const successStoryDetailStore = rootStore.successStoryDetailStore
export const investmentPotentialDetailStore = rootStore.investmentPotentialDetailStore
export const newsStore = rootStore.newsStore
export const newsDetailStore = rootStore.newsDetailStore
export const tourismStore = rootStore.tourismStore
export const tourismDetailStore = rootStore.tourismDetailStore
export const investmentRealizationStore = rootStore.investmentRealizationStore
export const linkStore = rootStore.linkStore
export const regionStore = rootStore.regionStore
export const investmentChallengeStore = rootStore.investmentChallengeStore
export const uploadFileInvestmentChallengeStore = rootStore.uploadFileInvestmentChallengeStore
export const resetPasswordStore = rootStore.resetPasswordStore
export default rootStore
