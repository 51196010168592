import { makeAutoObservable, toJS } from 'mobx'
import { ROW_PER_PAGE } from '../constants/constant'
import { downloadCalendarEvents, fetchAllTourism } from '../services/tourismService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class TourismState {
	data = []
	isLoading = false
	currentPage = 1
	hasNext = false

	region = ''
	searchTerm = ''
	category = []
	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async () => {
		if (this.isLoading) return
		this.isLoading = true
		try {
			const response = await fetchAllTourism(this.currentPage, ROW_PER_PAGE, this.searchTerm, this.category, this.region)
			if (this.currentPage === 1) {
				this.data = response.data.data
			} else {
				this.data = this.data.concat(response.data.data)
			}
			this.hasNext = response.data.hasNextPage
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'tourism' }))
		} finally {
			this.isLoading = false
		}
	}

	nextPage = async () => {
		this.currentPage += 1
		this.fetchData()
	}

	search = async (searchTerm) => {
		this.currentPage = 1
		this.searchTerm = searchTerm
		setTimeout(() => this.fetchData(), 500)
	}

	selectRegion = async (region) => {
		this.currentPage = 1
		this.region = region
		await this.fetchData()
	}

	selectCategory = async (category) => {
		this.currentPage = 1
		this.category = this.category.concat(category)
		await this.fetchData()
	}

	unSelectCategory = async (category) => {
		this.currentPage = 1
		this.category = this.category.filter((cat) => cat !== category)
		await this.fetchData()
	}

	sendDownloadCalendarEvents = async (payload) => {
		try {
			const response = await downloadCalendarEvents(payload)
			console.log('store:', { response })
			return response.data
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'tourism' }))
		}
	}
}

const TourismStore = new TourismState()
export default TourismStore
