import { makeAutoObservable } from 'mobx'
import {
  fetchAnnouncement,
  fetchTimeline,
  fetchAboutIC,
  login as loginService,
  register as registerService,
  forgotPassword as forgotPasswordService,
  uploadPhotoProfile as uploadPhotoProfileService,
  fetchHistoryUpload as fetchHistoryUploadService,
  deleteHistoryUpload as deleteHistoryUploadService
} from '../services/investmentChallengeService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class InvestmentChallengeStore {
  isLoading = false
  isLoggedIn = false
  isModalLoginOpen = true
  isModalChangePasswordOpen = false
  isModalConfirmDeleteOpen = false
  idDelete = null
  aboutIC = null
  announcement = null
  timeline = null

  activeTab = 'about'

  name = ''
  login_key = ''
  password = ''
  confirm_password = ''
  birthday = ''
  birth_place = ''

  message = ''

  history_upload = []

  photoPath = process.env.REACT_APP_IMAGE_URL

  photoProfilePath = ''

  constructor() {
    const user = localStorage.getItem('user')
    const parsedUser = user ? JSON.parse(user) : null
    this.photoProfilePath = parsedUser && parsedUser.photo_profile ? `${this.photoPath}${parsedUser.photo_profile}` : `${this.photoPath}`

    makeAutoObservable(this)
  }

  login = async () => {
    this.isLoading = true
    try {
      const response = await loginService(this.login_key, this.password, 'email')
      localStorage.setItem('token', response.data.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.data.user))
      localStorage.setItem('login_expired', JSON.stringify(response.data.data.expired_at))
      this.isLoggedIn = true
      this.isModalLoginOpen = false
      this.activeTab = 'profile'
      this.login_key = ''
      this.password = ''
      toast.success(response.data.message)
      return response
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'login' }))
    } finally {
      this.isLoading = false
    }
  }

  logout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('login_expired')
    this.isLoggedIn = false
    this.isModalLoginOpen = true
    this.activeTab = 'about'
  }

  register = async () => {
    this.isLoading = true
    try {
      const response = await registerService(this.name, this.login_key, this.password, this.confirm_password, this.birthday, this.birth_place)
      this.name = ''
      this.login_key = ''
      this.password = ''
      this.confirm_password = ''
      this.birthday = ''
      this.birth_place = ''
      this.isModalLoginOpen = false
      toast.success(response.data.message)
      return response
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'register' }))
    } finally {
      this.isLoading = false
    }
  }

  forgotPassword = async () => {
    this.isLoading = true
    try {
      const response = await forgotPasswordService(this.login_key)
      this.login_key = ''
      this.isModalLoginOpen = false
      toast.success(response.data.message)
      return response
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'forgot password' }))
    } finally {
      this.isLoading = false
    }
  }

  changePassword = async () => {
    this.isLoading = true
    try {
      const response = await forgotPasswordService(this.login_key)
      this.login_key = ''
      this.isModalChangePasswordOpen = false
      toast.success(response.data.message)
      return response
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'change password' }))
    } finally {
      this.isLoading = false
    }
  }

  resetLoginState = () => {
    this.login_key = ''
    this.password = ''
  }

  fetchAnnouncement = async () => {
    if (this.isLoading) return

    this.isLoading = true
    try {
      const response = await fetchAnnouncement(1, 1)
      this.announcement = response.data.data[0]
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'announcement' }))
    } finally {
      this.isLoading = false
    }
  }

  fetchAboutIC = async () => {
    if (this.isLoading) return

    this.isLoading = true
    try {
      const response = await fetchAboutIC(1, 1)
      this.aboutIC = response.data.data[0]
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'about ic' }))
    } finally {
      this.isLoading = false
    }
  }

  fetchTimeline = async () => {
    if (this.isLoading) return

    this.isLoading = true
    try {
      const response = await fetchTimeline(1, 1)
      this.timeline = response.data.data[0]
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'timeline' }))
    } finally {
      this.isLoading = false
    }
  }

  uploadPhotoProfile = async (file) => {
    this.isLoading = true
    const id = JSON.parse(localStorage.getItem('user')).id
    if (!id) {
      toast.error(i18n.t('please-login'))
      return
    }
    const payload = {
      id,
      photo_profile: file
    }
    try {
      const response = await uploadPhotoProfileService(payload)
      localStorage.setItem('user', JSON.stringify(response.data.data))
      this.photoProfilePath = `${this.photoPath}${response.data.data.photo_profile}`
      toast.success(response.data.message)
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'upload photo profile' }))
    } finally {
      this.isLoading = false
    }
  }

  fetchHistoryUpload = async () => {
    this.isLoading = true
    const id = JSON.parse(localStorage.getItem('user')).id
    if (!id) {
      toast.error(i18n.t('please-login'))
      return
    }
    try {
      const response = await fetchHistoryUploadService(id)
      this.history_upload = response.data
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.data.message)
      else toast.error(i18n.t('service-error', { field: 'history upload' }))
    } finally {
      this.isLoading = false
    }
  }

  openConfirmDelete = (id) => {
    this.idDelete = id
    this.isModalConfirmDeleteOpen = true
  }

  closeConfirmDelete = () => {
    this.idDelete = null
    this.isModalConfirmDeleteOpen = false
  }

  deleteHistoryUpload = async () => {
    this.isLoading = true
    try {
      const response = await deleteHistoryUploadService(this.idDelete)
      toast.success(response.message)
      await this.fetchHistoryUpload()
      this.isModalConfirmDeleteOpen = false
      this.idDelete = null
    } catch (error) {
      console.error(error)
      if (error.response) toast.error(error.response.message)
      else toast.error(i18n.t('service-error', { field: 'delete history upload' }))
    } finally {
      this.isLoading = false
    }
  }

  resetState = () => {
    this.isLoading = false
    this.announcement = null
    this.timeline = null
  }
}

const investmentChallengeStore = new InvestmentChallengeStore()
export default investmentChallengeStore
