import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import General from "../../components/Faq/general";
import Trade from "../../components/Faq/trade";
import Investment from "../../components/Faq/investment";
import Tourism from "../../components/Faq/tourism";
import Breadcrumb from '../../components/Breadcrumb'
import Sidebar from '../../components/Sidebar'

const Faq = () => {
  const { t } = useTranslation()

  const breadcrumbItems = [
    {
      label: (
        <>
          <i className='ms-2 fa fa-home'></i> {t('home')}
        </>
      ),
      href: '/'
    },
    { label: t('about-us') }
  ]

  const tabs = [
    {
      key: 'general',
      content: <General />
    },
    {
      key: 'trade',
      content: <Trade />
    },
    {
      key: 'investment',
      content: <Investment />
    },
    {
      key: 'tourism',
      content: <Tourism />
    }
  ]

  return (
    <div className='relative'>
      <div className='flex justify-center relative'>
        <div className='max-w-6xl w-full mt-12 px-2 font-sans'>
          <Breadcrumb items={breadcrumbItems} />
          <div className='md:flex mt-7'>
            <Sidebar tabData={tabs} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;
