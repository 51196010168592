import React from 'react'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/swiper-bundle.css'
import { useTranslation } from 'react-i18next'

export default function HomeSlider({ isHome = true }) {
  const { t } = useTranslation()

  const handleScroll = (element) => {
    const targetElement = document.getElementById(element)
    if (targetElement) {
      const startPosition = window.pageYOffset
      const targetPosition = targetElement.getBoundingClientRect().top + startPosition
      const distance = targetPosition - startPosition
      const duration = 1000
      let startTime = null

      const smoothScroll = (currentTime) => {
        if (!startTime) startTime = currentTime
        const timeElapsed = currentTime - startTime
        const run = ease(timeElapsed, startPosition, distance, duration)

        window.scrollTo(0, run)

        if (timeElapsed < duration) requestAnimationFrame(smoothScroll)
      }

      const ease = (t, b, c, d) => {
        t /= d / 2
        if (t < 1) return (c / 2) * t * t + b
        t--
        return (-c / 2) * (t * (t - 2) - 1) + b
      }

      requestAnimationFrame(smoothScroll)
    }
  }

  return (
    <div className='bg-slate-900 h-[480px] md:h-[560px] text-white relative'>
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        className='mySwiper'
      >
        <SwiperSlide>
          <div
            className='bg-slate-900 relative h-[480px] md:h-[560px] flex flex-col justify-center px-12 md:px-32 bg-cover bg-bottom'
            style={{
              backgroundImage: `url('../assets/images/image_slide/image_blank/banner1.png')`
            }}
          >
            <div className='absolute inset-0 bg-black opacity-40'></div>

            <div className='relative z-10'>
              <span className='text-3xl md:text-7xl text-primary-light font-lighthouse'>{t('welcomeMessage')}</span>
              <p className='mt-4 text-sm md:text-lg'>{t('subheading')}</p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className='bg-slate-900 relative h-[480px] md:h-[560px] flex flex-col justify-center px-12 md:px-32 bg-cover bg-bottom'
            style={{
              backgroundImage: `url('../assets/images/image_slide/image_blank/banner2.png')`
            }}
          >
            <div className='absolute inset-0 bg-black opacity-40'></div>

            <div className='relative z-10'>
              <h1 className='mb-4 text-3xl md:text-5xl font-bold'>{t('ease-of-doing-business-title')}</h1>
              <p className='text-sm md:text-lg'>{t('ease-of-doing-business-description')}</p>
              <div className='mt-4 flex flex-col'>
                <a href='/investment' className='w-56 md:w-96 text-center text-xs md:text-sm btn-outline mt-3 hover:bg-gold truncate text-ellipsis overflow-hidden'>
                  {t('explore-ease-business')}
                </a>
                <a href='/investment_challenge' className='w-56 text-center md:w-96 text-xs md:text-sm btn-outline mt-3 hover:bg-gold'>
                  {t('investment-challenge')}
                </a>
                <button className='w-56 md:w-96 text-xs md:text-sm btn-outline mt-3 hover:bg-gold' onClick={() => handleScroll('investment-demand-form')}>
                  {t('investment-demand')}
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className='bg-slate-900 relative h-[480px] md:h-[560px] flex flex-col justify-center px-12 md:px-32 bg-cover bg-bottom'
            style={{
              backgroundImage: `url('../assets/images/image_slide/image_blank/banner6.png')`
            }}
          >
            <div className='absolute inset-0 bg-black opacity-40'></div>

            <div className='relative z-10'>
              <h1 className='mb-4 font-bold text-3xl md:text-5xl'>{t('central-java-trade-msmes-title')}</h1>
              <p className='text-sm md:text-lg'>{t('central-java-trade-msmes-description')}</p>
              <div className='mt-4 flex flex-col space-y-2'>
                <Link to='/trade' className='w-56 md:w-96 text-xs md:text-sm text-center btn-outline mt-3 hover:bg-gold'>
                  {t('visit-central-java-msmes')}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className='bg-slate-900 relative h-[480px] md:h-[560px] flex flex-col justify-center px-12 md:px-32 bg-cover bg-bottom'
            style={{
              backgroundImage: `url('../assets/images/image_slide/image_blank/banner4.png')`
            }}
          >
            <div className='absolute inset-0 bg-black opacity-40'></div>

            <div className='relative z-10'>
              <h1 className='mb-4 text-3xl md:text-5xl font-bold'>{t('indonesian-tourist-destinations-title')}</h1>
              <p className='text-sm md:text-lg'>{t('indonesian-tourist-destinations-description')}</p>
              <div className='mt-4 flex flex-col space-y-2'>
                <Link to='/pariwisata' className='w-56 md:w-96 text-center text-xs md:text-sm btn-outline mt-3 hover:bg-gold'>
                  {t('explore-charm-central-java')}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className='bg-slate-900 relative h-[480px] md:h-[560px] flex flex-col justify-center px-12 md:px-32 bg-cover bg-bottom'
            style={{
              backgroundOrigin: 'border-box',
              backgroundImage: `url('../assets/images/image_slide/image_blank/banner10.png')`
            }}
          >
            <div className='absolute inset-0 bg-black opacity-40'></div>

            <div className='relative z-10 flex justify-between items-center'>
              <div>
                <h1 className='mb-4 font-bold text-3xl md:text-5xl'>Central Java Investment Business Forum 2024</h1>
                <p className='text-sm md:text-lg'>Enhancing Sustainable Growth Through Green And Circular Economy</p>
                <p className='text-sm md:text-lg'>Grand Batang City, October 29 - 30 2024</p>
                <div className='mt-4 flex flex-col space-y-2'>
                  <a href='https://bit.ly/CentralJavaProjects' className='max-w-80 w-56 md:w-96 btn-outline mt-3 hover:bg-gold text-xs md:text-sm text-center' target='_blank' rel='noreferrer'>
                    {t('see-all')}
                  </a>
                </div>
              </div>
              <div className='hidden md:flex flex-col items-center min-w-48'>
                <img src='assets/images/image_slide/image_blank/qr-materi-cjibf.png' alt='QR' className='w-24 h-24 md:w-48 md:h-48 aspect-square rounded-md' />
                <p className='font-semibold'>{t('scan-for-more')}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div className='swiper-button-prev text-white'></div>
        <div className='swiper-button-next text-white'></div>
      </Swiper>
      {isHome && (
        <div role='button' onClick={() => handleScroll('target-section')} id='target-section' className='absolute z-40 -bottom-0 cursor-pointer'>
          <div className='flex w-screen justify-center'>
            <div className='bg-primary-light flex items-center justify-center rounded-t-xl px-8 py-3'>
              <i className='fa fa-chevron-down border-4 border-white mr-2 rounded-full p-1'></i>
              <span className='sm:text-lg md:text-xl'>{t('find_jateng')}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
