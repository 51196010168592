import { makeAutoObservable } from 'mobx'
import { sendInvestmentChallenge } from '../services/investmentChallengeService'
import { toast } from 'react-toastify'
import i18n from '../i18n'

class UploadFileInvestmentChallengeStore {
	name = ''
	dinas = ''
	region = ''
	title = ''
	sector = ''
	file_proposal = null
	file_presentation = null
	file_support_1 = null
	file_support_2 = null
	file_support_3 = null
	file_support_4 = null
	file_support_5 = null

	isModalUploadFileOpen = false

	constructor() {
		makeAutoObservable(this)
	}

	submitInvestmentChallenge = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'))
			if (!user) return

			const response = await sendInvestmentChallenge({
				user_id: user.id,
				nama: this.name,
				asal_dinas: this.dinas,
				location: this.region,
				judul_proyek: this.title,
				sektor_proyek: this.sector,
				proposal: this.file_proposal,
				presentation: this.file_presentation,
				file_pendukung1: this.file_support_1,
				file_pendukung2: this.file_support_2,
				file_pendukung3: this.file_support_3,
				file_pendukung4: this.file_support_4,
				file_pendukung5: this.file_support_5
			})
			if (response) {
				this.isModalUploadFileOpen = false
				toast.success(response.data.message)
				this.resetState()
			}
		} catch (error) {
			console.error(error)
			if (error.response) toast.error(error.response.data.message)
			else toast.error(i18n.t('service-error', { field: 'investment challenge' }))
		}
	}

	resetState = () => {
		this.name = ''
		this.dinas = ''
		this.region = ''
		this.title = ''
		this.sector = ''
		this.file_proposal = null
		this.file_presentation = null
		this.file_support_1 = null
		this.file_support_2 = null
		this.file_support_3 = null
		this.file_support_4 = null
		this.file_support_5 = null
	}
}

const uploadFileInvestmentChallengeStore = new UploadFileInvestmentChallengeStore()
export default uploadFileInvestmentChallengeStore
