import React, { useEffect, useState } from "react";
import { tourismDetailStore } from "../../stores/_rootStore";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeadingUnderline from "../../components/HeadingUnderline";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import i18n from "../../i18n";

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs, Autoplay } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/swiper-bundle.css'

function TourismDetail() {
	const [thumbsSwiper, setThumbsSwiper] = useState(null)
	const [isFullscreen, setIsFullscreen] = useState(false)
	const { postName } = useParams()
	const { t } = useTranslation()
	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('tourism') }, { label: tourismDetailStore.data?.post_title }]
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			try {
				await tourismDetailStore.fetchData(postName)
				await tourismDetailStore.fetchOtherData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		// eslint-disable-next-line
	}, [postName, i18n.language, postName])

	useEffect(() => {
		const handleFullScreenChange = () => {
			setIsFullscreen(!!document.fullscreenElement)
		}

		document.addEventListener('fullscreenchange', handleFullScreenChange)
		document.addEventListener('webkitfullscreenchange', handleFullScreenChange)
		document.addEventListener('mozfullscreenchange', handleFullScreenChange)
		document.addEventListener('MSFullscreenChange', handleFullScreenChange)

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange)
			document.removeEventListener('webkitfullscreenchange', handleFullScreenChange)
			document.removeEventListener('mozfullscreenchange', handleFullScreenChange)
			document.removeEventListener('MSFullscreenChange', handleFullScreenChange)
		}
	}, [])

	if (tourismDetailStore.data !== undefined)
		return (
			<div className='flex flex-col justify-center mb-12'>
				<img src={`${process.env.REACT_APP_IMAGE_URL}${tourismDetailStore.data?.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`} className='w-full h-[335px] object-cover' />

				<div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
					<Breadcrumb items={breadcrumbItems} />
					<div className='grid grid-cols-1 md:grid-cols-10 gap-10 gap-y-5 mt-5'>
						<div className='md:col-span-7'>
							<h1 className='text-3xl font-bold mb-2 mt-8'>{tourismDetailStore.data?.post_title}</h1>
							<HeadingUnderline />
							<div
								className='mt-4'
								dangerouslySetInnerHTML={{
									__html: `
								<style>
									body, p, div {
									line-height: 2 !important;
									}
								</style>
									${tourismDetailStore.data?.post_content}
								`
								}}
							></div>
						</div>
						<div className='md:col-span-3'>
							<h2 className='text-3xl mb-2 mt-8'>{t('other_tourism')}</h2>
							<HeadingUnderline />
							<div className='grid grid-cols-2 gap-5 mt-5'>
								{tourismDetailStore.otherData.map((item) => (
									<Card
										key={item.id}
										label={item.post_title.length > 30 ? item.post_title.slice(0, 30).concat('...') : item.post_title}
										imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
										href={`/pariwisata/${item.post_name}`}
									/>
								))}
							</div>
						</div>
					</div>
					{tourismDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'galeri').length > 0 && (
						<div className='mt-5'>
							<h2>{t('gallery')}</h2>
							<HeadingUnderline />
							<div className='bg-slate-900 h-[320px] md:h-[560px] text-white relative'>
								<Swiper modules={[FreeMode, Navigation, Thumbs]} loop={true} thumbs={{ swiper: thumbsSwiper }} className='mySwiper'>
									{tourismDetailStore.data.post_meta
										.filter((meta) => meta.meta_key === 'galeri')
										.map((meta) => {
											return (
												<SwiperSlide>
													<div
														className={`bg-slate-900 relative ${isFullscreen ? 'h-auto' : 'h-[320px] md:h-[560px]'} flex flex-col justify-center bg-bottom`}
														onClick={(e) => {
															e.currentTarget.requestFullscreen()
														}}
													>
														{document.fullscreenElement && (
															<button className='absolute top-5 right-5 text-white text-4xl' onClick={() => document.exitFullscreen()}>
																<i className='fa fa-close'></i>
															</button>
														)}
														<img alt={meta.meta_value} src={`${process.env.REACT_APP_IMAGE_URL}${meta.meta_value}`} />
													</div>
												</SwiperSlide>
											)
										})}
								</Swiper>
							</div>
							<div className='mt-1'>
								<Swiper
									onSwiper={setThumbsSwiper}
									loop={true}
									spaceBetween={10}
									navigation={true}
									slidesPerView={4}
									freeMode={true}
									watchSlidesProgress={true}
									modules={[FreeMode, Navigation, Thumbs]}
									className='mySwiper'
								>
									{tourismDetailStore.data.post_meta
										.filter((meta) => meta.meta_key === 'galeri')
										.map((meta) => {
											return (
												<SwiperSlide>
													<div className='bg-slate-900 relative h-[80px] md:h-[120px] flex flex-col justify-center bg-cover bg-bottom'>
														<img alt={meta.meta_value} src={`${process.env.REACT_APP_IMAGE_URL}${meta.meta_value}`} />
													</div>
												</SwiperSlide>
											)
										})}
								</Swiper>
							</div>
						</div>
					)}
				</div>
			</div>
		)
}

export default observer(TourismDetail);
