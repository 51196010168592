import React, { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { investmentChallengeStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

function ModalLogin() {
	const [mode, setMode] = useState('login') // login, register, forgot-password

	const changeMode = (mode) => {
		setMode(mode)
		investmentChallengeStore.name = ''
		investmentChallengeStore.login_key = ''
		investmentChallengeStore.password = ''
		investmentChallengeStore.passwordConfirm = ''
		investmentChallengeStore.birthday = ''
		investmentChallengeStore.birth_place = ''
	}
	return (
		<Dialog.Root open={investmentChallengeStore.isModalLoginOpen} onOpenChange={() => (investmentChallengeStore.isModalLoginOpen = !investmentChallengeStore.isModalLoginOpen)}>
			<Dialog.Portal>
				<Dialog.Overlay className='DialogOverlay' />
				<Dialog.Content
					className='DialogContent'
					onOpenAutoFocus={(e) => {
						e.preventDefault()
					}}
				>
					<VisuallyHidden>
						<Dialog.Title>{mode}</Dialog.Title>
					</VisuallyHidden>
					<div className='flex justify-end'>
						<Dialog.Close>
							<Cross1Icon />
						</Dialog.Close>
					</div>
					<div className='flex flex-col justify-center gap-3'>
						{mode === 'login' && (
							<>
								<h1 className='text-2xl font-bold text-center'>Masuk</h1>
								<input
									type='text'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan email'}
									value={investmentChallengeStore.login_key}
									onChange={(e) => {
										investmentChallengeStore.login_key = e.target.value
									}}
								/>
								<input
									type='password'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan password'}
									value={investmentChallengeStore.password}
									onChange={(e) => {
										investmentChallengeStore.password = e.target.value
									}}
								/>
								<div className='flex justify-end'>
									<button
										className='text-gold hover:opacity-80'
										onClick={() => {
											changeMode('forgot-password')
										}}
									>
										Lupa Password?
									</button>
								</div>
								<button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.login} disabled={investmentChallengeStore.isLoading}>
									{investmentChallengeStore.isLoading ? 'Loading...' : 'Login'}
								</button>
								<p className='text-center'>
									Belum punya akun?
									<button
										className='text-gold hover:opacity-80'
										onClick={() => {
											changeMode('register')
										}}
									>
										Register
									</button>
								</p>
							</>
						)}
						{mode === 'register' && (
							<>
								<h1 className='text-2xl font-bold text-center'>Daftar</h1>
								<input
									type='text'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan nama'}
									value={investmentChallengeStore.name}
									onChange={(e) => (investmentChallengeStore.name = e.target.value)}
								/>
								<input
									type='text'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan email'}
									value={investmentChallengeStore.login_key}
									onChange={(e) => (investmentChallengeStore.login_key = e.target.value)}
								/>
								<input
									type='date'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan tanggal lahir'}
									value={investmentChallengeStore.birthday}
									onChange={(e) => (investmentChallengeStore.birthday = e.target.value)}
								/>
								<input
									type='text'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan tempat lahir'}
									value={investmentChallengeStore.birth_place}
									onChange={(e) => (investmentChallengeStore.birth_place = e.target.value)}
								/>
								<input
									type='password'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan password'}
									value={investmentChallengeStore.password}
									onChange={(e) => (investmentChallengeStore.password = e.target.value)}
								/>
								<input
									type='password'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Konfirmasi password'}
									value={investmentChallengeStore.confirm_password}
									onChange={(e) => (investmentChallengeStore.confirm_password = e.target.value)}
								/>

								<button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.register} disabled={investmentChallengeStore.isLoading}>
									{investmentChallengeStore.isLoading ? 'Loading...' : 'Register'}
								</button>
								<p className='text-center'>
									Sudah punya akun?{' '}
									<button className='text-gold hover:opacity-80' onClick={() => changeMode('login')}>
										Login
									</button>
								</p>
							</>
						)}
						{mode === 'forgot-password' && (
							<>
								<h1 className='text-2xl font-bold text-center'>Lupa Password</h1>
								<input
									type='text'
									className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
									placeholder={'Masukkan email'}
									value={investmentChallengeStore.login_key}
									onChange={(e) => (investmentChallengeStore.login_key = e.target.value)}
								/>

								<button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.forgotPassword} disabled={investmentChallengeStore.isLoading}>
									{investmentChallengeStore.isLoading ? 'Loading...' : 'Kirim'}
								</button>
								<button
									className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80'
									onClick={() => {
										changeMode('login')
									}}
								>
									Kembali
								</button>
							</>
						)}
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

export default observer(ModalLogin)
