import React, { useEffect, useRef, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import HeadingUnderline from '../HeadingUnderline'
import UploadFile from './UploadFile'
import ModalChangePassword from '../../pages/Challenge/ModalChangePassword'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { investmentChallengeStore } from '../../stores/_rootStore'
import { toast } from 'react-toastify'
import { validateImageResolution } from '../../lib/utils'
import ModalConfirmDeleteFile from '../../pages/Challenge/ModalConfirmDeleteFile'

function Profile() {
  const { t } = useTranslation()
  const docsPath = process.env.REACT_APP_INVESTMENT_CHALLENGE_DOCS_URL

  const inputPhotoProfileRef = useRef(null)

  const handleUploadPhotoProfileClick = () => {
    inputPhotoProfileRef.current.click()
  }

  const handlePhotoProfileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase()
      // Only .jpg, .jpeg, and .png files are accepted
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        toast.error(t('image-format'))
        return
      }
      // File size must be less than 2MB
      if (file.size > 2097152) {
        toast.error(t('image-max-size'))
        return
      }
      try {
        // Resolution must be 560x560 or less
        if (!(await validateImageResolution(file, 560, 560))) {
          toast.error(t('image-resolution'))
          return
        }
        await investmentChallengeStore.uploadPhotoProfile(file)
      } catch (error) {
        toast.error(error.message)
        return
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await investmentChallengeStore.fetchHistoryUpload()
    }
    fetchData()
  }, [])

  return (
    <div>
      <ModalChangePassword />
      <ModalConfirmDeleteFile />
      <div className='flex justify-between items-center mb-2'>
        <h1 className='text-3xl font-bold'>{t('profile')}</h1>
        <Popover.Root>
          <Popover.Trigger asChild>
            <button className='text-3xl w-4'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                <path d='M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z' />
              </svg>
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align='end'>
              <div className='bg-slate-50 shadow-lg'>
                <div className='flex flex-col w-36'>
                  <button
                    className='hover:border-l-4 hover:border-gold text-left p-2'
                    onClick={() => {
                      investmentChallengeStore.isModalChangePasswordOpen = true
                    }}
                  >
                    {t('change-password')}
                  </button>
                  <button className='hover:border-l-4 hover:border-gold text-left p-2' onClick={investmentChallengeStore.logout}>
                    {t('logout')}
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
      <HeadingUnderline />
      <div className='flex gap-2 items-center h-56'>
        <div className='flex justify-center items-center flex-col'>
          <div className='text-slate-400 text-8xl'>
            <img src={investmentChallengeStore.photoProfilePath} alt='Photo profile' onError={(e) => (e.target.src = 'assets/images/no-data-image.jpg')} width={150} className='rounded-full' />
          </div>
          <p className='font-bold'>{localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).name : ''}</p>
        </div>
        <div className='flex flex-col'>
          <button className='bg-gold py-2 text-white hover:opacity-80' onClick={handleUploadPhotoProfileClick}>
            {t('upload-photo')}
          </button>
          <input type='file' ref={inputPhotoProfileRef} onChange={(e) => handlePhotoProfileChange(e)} hidden />
          <p>{t('image-max-resolution')}</p>
        </div>
      </div>
      <div className='flex justify-between items-center mb-2'>
        <h2 className='text-3xl font-bold'>{t('history-upload-file')}</h2>
        <UploadFile />
      </div>
      <HeadingUnderline />
      {investmentChallengeStore.history_upload.map((item) => (
        <div key={item.id} className='border border-slate-400 p-2 my-2 shadow-md bg-white rounded-md relative'>
          <button
            className='absolute top-2 right-2 text-lg bg-red-500 hover:bg-red-400 text-white aspect-square w-7 rounded-full flex items-center justify-center'
            onClick={() => investmentChallengeStore.openConfirmDelete(item.id)}
          >
            <i className='fa fa-close'></i>
          </button>
          <p>
            <b>{t('uploader-name')}</b> : {item.nama}
          </p>
          <p>
            <b>{t('uploader-service-origin')} </b>: {item.asal_dinas}
          </p>
          <p>
            <b>{t('uploader-region-origin')}</b> : {item.location}
          </p>
          <p>
            <b>{t('title-investment-profile')}</b> : {item.judul_proyek}
          </p>
          <p>
            <b>{t('sector-investment-project')}</b> : {item.sektor_proyek}
          </p>
          <p>
            <b>{t('uploaded-at')}</b> : {new Date(item.created_at).toLocaleDateString('id-ID')}
          </p>
          <div className='mt-5 flex gap-2'>
            {item.proposal && (
              <a href={`${docsPath}${item.proposal}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('proposal-file')}
              </a>
            )}
            {item.presentation && (
              <a href={`${docsPath}${item.presentation}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('presentation-file')}
              </a>
            )}
            {item.file_pendukung1 && (
              <a href={`${docsPath}${item.file_pendukung1}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('support-file')} 1
              </a>
            )}
            {item.file_pendukung2 && (
              <a href={`${docsPath}${item.file_pendukung2}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('support-file')} 2
              </a>
            )}
            {item.file_pendukung3 && (
              <a href={`${docsPath}${item.file_pendukung3}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('support-file')} 3
              </a>
            )}
            {item.file_pendukung4 && (
              <a href={`${docsPath}${item.file_pendukung4}`} target='_blank' rel='noreferrer' className='bg-gold text-white p-2 rounded-md hover:opacity-80'>
                {t('support-file')} 4
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default observer(Profile)
